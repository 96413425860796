<template>
  <div>
    <div class="contain">
      <div class="information">
        <div class="title">企业信息</div>
        <!-- 个人信息 -->
        <div class="content">
          <div class="flex1">
            <div class="line">
              <div class="name">公司名称</div>
              <input type="text" placeholder="请输入" />
            </div>
            <div class="line">
              <div class="name">联系人</div>
              <input type="text" placeholder="请输入" />
            </div>
            <div class="line">
              <div class="name">证件类别</div>
              <input type="text" placeholder="请输入" />
              <i class="el-icon-arrow-down"></i>
            </div>
            <div class="line">
              <div class="name">手机号码</div>
              <input type="text" placeholder="请输入" />
            </div>
            <div class="line">
              <div class="name">证件编号</div>
              <input type="text" placeholder="请输入" />
            </div>
            <div class="line">
              <div class="name">联系电话</div>
              <input type="text" placeholder="请输入" />
            </div>
            <div class="line">
              <div class="name">公司性质</div>
              <input type="text" placeholder="请选择" />
              <i class="el-icon-arrow-down"></i>
            </div>
            <div class="line">
              <div class="name">邮编</div>
              <input type="text" placeholder="请输入" />
            </div>
            <div class="line">
              <div class="name">公司规模</div>
              <input type="text" placeholder="请选择" />
              <i class="el-icon-arrow-down"></i>
            </div>
            <div class="line">
              <div class="name">公司网址</div>
              <input type="text" placeholder="请输入" />
            </div>
            <div class="line">
              <div class="name">所属行业</div>
              <input type="text" placeholder="请选择" />
              <i class="el-icon-menu"></i>
            </div>
            <div class="line">
              <div class="name">公司邮箱</div>
              <input type="text" placeholder="请输入" />
            </div>
            <div class="line">
              <div class="name">招聘大类</div>
              <input type="text" placeholder="请选择" />
              <i class="el-icon-menu"></i>
            </div>
            <div class="line">
              <div class="name">营业执照</div>
              <img src="../../assets/img/yyzz.png" alt="" />
            </div>
            <div class="line">
              <div class="name">公司介绍</div>
              <!-- <input type="textarea" placeholder="请输入" style="height:145px"/> -->
              <textarea></textarea>
            </div>
          </div>
          <!-- 按钮 -->
          <div class="btn" @click="goto('/companycenter')">完成</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  methods: {
    goto(url) {
      this.$router.push(url);
    },
  },
};
</script>
<style lang="scss" scoped>
.contain {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  .information {
    width: 1000px;
    // height: 560px;
    background: white;
    border-radius: 4px;
    padding: 25px 35px 45px;
    box-sizing: border-box;
    .title {
      width: 64px;
      color: #276bf2;
      font-size: 16px;
      border-bottom: 4px solid #276bf2;
      padding-bottom: 15px;
      margin-bottom: 35px;
    }
  }
}
// 内容
.content {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding: 20px;
  font-size: 14px;
  color: #333;
  position: relative;
  .line {
    width: 50%;
    display: flex;
    line-height: 40px;
    position: relative;
    margin-bottom: 15px;
    .name {
      width: 85px;
      text-align: right;
      margin-right: 20px;
    }
    input {
      width: 245px;
      border: 1px solid #ececec;
      box-sizing: border-box;
      border-radius: 4px;
      text-indent: 1em;
    }
    img {
      position: absolute;
      width: 115px;
      left: 105px;
      top: 0;
    }
    textarea {
      width: 245px;
      border: 1px solid #ececec;
      box-sizing: border-box;
      padding: 0;
      border-radius: 4px;
      text-indent: 1em;
    }
    i {
      position: absolute;
      right: 105px;
      line-height: 40px;
      color: #276bf2;
    }
    .el-icon-arrow-down {
      color: #999;
    }
  }
}
//   按钮
.btn{
    width: 300px;
    height: 45px;
    background: #276bf2;
    line-height: 45px;
    color: white;
    text-align: center;
    margin: 0 auto;
    margin-top: 40px;
    border-radius: 4px;
}
</style>